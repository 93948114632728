export const GET_ELIGIBILITY_REQUEST = 'GET_ELIGIBILITY_REQUEST';
export const GET_ELIGIBILITY_SUCCESS = 'GET_ELIGIBILITY_SUCCESS';
export const GET_ELIGIBILITY_FAILURE = 'GET_ELIGIBILITY_FAILURE';

export const SAVE_ELIGIBILITY_REQUEST = 'SAVE_ELIGIBILITY_REQUEST';
export const SAVE_ELIGIBILITY_SUCCESS = 'SAVE_ELIGIBILITY_SUCCESS';
export const SAVE_ELIGIBILITY_FAILURE = 'SAVE_ELIGIBILITY_FAILURE';
export const getEligibilityAction = (subjectID) => {
    return {
        type: GET_ELIGIBILITY_REQUEST,
        subjectID
    }
};

export const saveEligibilityAction = (subjectID, data, sign) => {
    return {
        type: SAVE_ELIGIBILITY_REQUEST,
        subjectID, data, sign
    }
};