import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import axios from "axios";
import * as actionTypes from '../_actions/eligibilityAction';
import { message } from 'antd';

function getEligibilityAPI(action) {
    return axios.get('/eligibility/' + action.subjectID);
}
function* getEligibility(action) {
    const result = yield call(() =>getEligibilityAPI(action));
    try {
        yield put({
            type: actionTypes.GET_ELIGIBILITY_SUCCESS,
            payload: result.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.GET_ELIGIBILITY_FAILURE,
        })
    }
}
function* watchGetEligibility() {
    yield takeLatest(actionTypes.GET_ELIGIBILITY_REQUEST, getEligibility);
}

function saveEligibilityAPI(action) {
    const config = {headers: {'TI-DS-ID': action.sign.id, 'TI-DS-PW': action.sign.password}};
    return axios.put('/eligibility/' + action.subjectID, action.data, config);
}
function* saveEligibility(action) {
    const result = yield call(() =>saveEligibilityAPI(action));
    if(result.status === 200) {
        message.success({content: 'The report was saved successfully.'}).then();
        setTimeout(() => {
            window.location.reload();
        }, [1000]);
    }
    try {
        yield put({
            type: actionTypes.SAVE_ELIGIBILITY_SUCCESS,
        });
    } catch (err) {
        yield put({
            type: actionTypes.SAVE_ELIGIBILITY_FAILURE,
        })
    }
}
function* watchSaveEligibility() {
    yield takeLatest(actionTypes.SAVE_ELIGIBILITY_REQUEST, saveEligibility);
}

export default function* loginSaga() {
    yield all([
        fork(watchGetEligibility),
        fork(watchSaveEligibility),
    ])
}