import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { Layout, Row, Spin } from 'antd';

import auth from './hoc/auth';
import adminAuth from './hoc/adminAuth';

const TestPage = lazy(() => import('./components/Page/Test/TestPage'));
const TestPage2 = lazy(() => import('./components/Page/Test/TestPage2'));

const MainPage = lazy(() => import('./components/Page/User/MainPage'));
const OverviewPage =  lazy(() => import('./components/Page/User/OverviewPage.js'));
const ListPage = lazy(() => import('./components/Page/User/ListPage.js'));
const ProjectPage = lazy(() => import('./components/Page/Admin/ProjectPage'));
const DMPage = lazy(() => import('./components/Page/DM/ProjectPage'));
const MockTrainingPage = lazy(() => import('./components/Page/DM/MockTrainingPage'));
const ExpiredPage = lazy(() => import('./components/Page/Auth/ExpiredPage'));
const AppHeader = lazy(() => import('./components/Layout/Header/AppHeader'));
const AppSider = lazy(() => import('./components/Layout/Sider/AppSider'));
const AppFooter = lazy(() => import('./components/Layout/Footer/AppFooter'));
const OncoSingleReportPage = lazy(() => import('./components/Page/Report/Onco/OncoSingleReportPage'));
const OncoMultiReportPage =  lazy(() => import('./components/Page/Report/Onco/OncoMultiReportPage'));
const UploadPage =  lazy(() => import('./components/Page/Upload/UploadPage'));
const QCPage = lazy(() => import('./components/Page/QC/QCPage'));
const QueryPage = lazy(() => import('./components/Page/User/QueryPage'));
const LoginPage = lazy(() => import('./components/Page/Auth/LoginPage'));
const RegistrationPage = lazy(() => import('./components/Page/Auth/RegistrationPage'));
const UserPage = lazy(() => import('./components/Page/Admin/UserPage'));
const LogPage = lazy(() => import('./components/Page/Admin/LogPage'));
const PreviousVisitErrorPage = lazy(() => import('./components/Page/Error/PreviousVisitErrorPage'));
const ProjectErrorPage = lazy(() => import('./components/Page/Error/ProjectErrorPage'));
const NonOncoSingleReportPage =  lazy(() => import('./components/Page/Report/NonOnco/NonOncoSingleReportPage'));
const EligibilityPage = lazy(() => import('./components/Page/Eligibility/EligibilityPage'));
const NoWrittenReportPage = lazy(() => import('./components/Page/Error/NoWrittenReportPage'));

const { Content } = Layout;

const App = () => {
    const url = window.location.pathname;
    const padding = url.includes('/report') || url.includes('/multi') || url.includes('/inputTest') || url.includes('/nonReport') || url.includes('/eligibility') ?
        { paddingLeft: '5px', paddingRight: '5px' } : { padding: '15px' };

    return (
        <Router>
            <Suspense fallback={<Row justify='center' align='middle' style={{minHeight: '100vh'}}><Spin size="large"/></Row>}>
                <Layout style={{ minHeight: '100vh' }}>
                    <AppSider/>
                    <Layout>
                        <AppHeader/>
                        <Content style={padding}>
                            <Switch>
                                <Route path="/test" component={TestPage}/>
                                <Route path="/test2" component={TestPage2}/>

                                <Route exact path="/" component={LoginPage}/>
                                <Route exact path="/registration" component={RegistrationPage}/>
                                <Route exact path="/expired" component={ExpiredPage}/>
                                <Route exact path="/main" component={auth(MainPage)}/>
                                <Route exact path="/overview/:projectID" component={auth(OverviewPage)}/>
                                <Route exact path="/list/:projectID/:viewMode" component={auth(ListPage)}/>
                                <Route exact path="/list-todo/:projectID/:viewMode/:todo" component={auth(ListPage)}/>

                                <Route exact path="/admin" component={adminAuth(ProjectPage, true)}/>
                                <Route exact path="/admin/:projectID/:viewMode" component={adminAuth(ListPage, true)}/>
                                <Route exact path="/admin/user" component={adminAuth(UserPage, true)}/>
                                <Route exact path="/admin/log" component={adminAuth(LogPage, true)}/>

                                <Route exact path="/dm" component={auth(DMPage)}/>
                                <Route exact path="/dm/mock" component={auth(MockTrainingPage)}/>

                                <Route exact path="/report/:projectID/:subjectID/:timepointID/:reportType/:reportIndex" component={auth(OncoSingleReportPage)}/>
                                <Route exact path="/multi/:projectID/:subjectID/:pickIndex/:reportType" component={auth(OncoMultiReportPage)}/>

                                <Route exact path="/nonReport/:projectID/:subjectID/:timepointID/:reportType/:reportIndex" component={auth(NonOncoSingleReportPage)}/>

                                <Route exact path='/eligibility/:projectID/:subjectID' component={auth(EligibilityPage)}/>

                                <Route exact path="/upload/:projectID" component={auth(UploadPage)}/>
                                <Route exact path="/qc" component={auth(QCPage)}/>
                                <Route path="/query" component={auth(QueryPage)}/>
                                <Route path='/noProject' component={ProjectErrorPage}/>
                                <Route path='/timepointErrorPage' component={PreviousVisitErrorPage}/>
                                <Route path='/NoWrittenReportPage' component={NoWrittenReportPage}/>
                                <Route path="*" component={ExpiredPage}/>
                            </Switch>
                        </Content>
                        <AppFooter/>
                    </Layout>
                </Layout>
            </Suspense>
        </Router>
    );
}

export default App;
